import _ from 'lodash';
import { Validator } from '../Validator';

Validator.create<RegExp, string, RegExp|string>( {
  name : 'regexp',
  parse( opts ) {
    if ( _.isString( opts.config ) ) {
      const x = /^\s*\/(.*)\/([gsmiu]*)\s*$/u.exec( opts.config );
      if ( x ) opts.config = new RegExp( x[ 1 ], x[ 2 ] );
    }
    if ( _.isRegExp( opts.config ) ) return;
    throw new TypeError( `regexp: config must be a RegExp` );
  },
  isValid( { value, config } ) {
    if ( ! _.isString( value ) ) return;
    return config.test( value );
  },
  message( { config } ) {
    const re = config.toString();
    return `does not match the regular expression ${re}`;
  },
} );
