import { Type } from '../Type';

Type.create( {
  name        : 'username',
  validators  : [ 'string', 'email' ],
  coerce( value ) { return String( value ).toLowerCase(); },
  form        : {
    type        : 'Email',
  },
  face        : {
    type        : 'Email',
  },
  faker       : 'internet.email',
} );
