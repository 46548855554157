import _ from 'lodash';
import { Type } from '../Type';

Type.create( {
  name        : 'boolean',
  validators  : [ 'boolean' ],
  coerce( value ) {
    if ( _.isNil( value ) ) return null;
    if ( _.isBoolean( value ) ) return value;
    if ( _.isString( value ) ) {
      if ( value === '' ) return null;
      const lower = value.toLowerCase();
      if ( _.includes( [ 'false', 'no', '0' ], lower ) ) return false;
      if ( _.includes( [ 'true', 'yes', '1' ], lower ) ) return true;
    }
    if ( _.isNumber( value ) ) {
      if ( value === 0 ) return false;
      if ( value === 1 ) return true;
    }
    return null;
  },
  faker       : 'datatype.boolean',
  form        : {
    type        : 'Checkbox',
  },
  face        : {
    type        : 'BooleanIcon',
  },
} );
