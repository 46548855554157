import _ from 'lodash';
import { Validator } from '../Validator';

Validator.create<unknown, string>( {
  name    : 'word',
  message : 'must be a single word',
  isValid( { value } ) {
    return _.isString( value ) && /^[-\w]+$/u.test( value );
  },
} );
