import { Type } from '../Type';

Type.create( {
  name        : 'slug',
  validators  : [ 'string', 'slug' ],
  form        : {
    type        : 'Text',
  },
  face        : {
    type        : 'Text',
  },
  faker       : 'lorem.slug',
} );
