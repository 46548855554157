import _ from 'lodash';
import { Validator } from '../Validator';

// eslint-disable-next-line no-useless-escape
const re = /^[a-z0-9-_]+$/iu;
Validator.create<unknown, string>( {
  name    : 'slug',
  message : 'must be a valid slug',
  isValid( { value } ) {
    if ( ! _.isString( value ) ) return false;
    return re.test( value );
  },
} );
