import _ from 'lodash';
import { ValidationContext } from '../context';

export type RangeConfigType = { min?: number; max?: number; };
export type RangeOptionType =
  | `${number}-${number}`
  | `${number}-`
  | `-${number}`
  | number
  | RangeConfigType;

export function parseRange<V=unknown>(
  context: ValidationContext<RangeOptionType, V>,
  defaultEnd: 'min' | 'max' | 'both' = 'both',
) {
  if ( _.isString( context.config ) ) {
    if ( context.config.includes( '-' ) ) {
      const [ min, max ] = context.config.split( /[\s-]+/u );
      context.config = {
        min : min.length ? parseInt( min ) : 0,
        max : max.length ? parseInt( max ) : Infinity,
      };
    } else if ( defaultEnd === 'both' ) {
      context.config = {
        min : parseInt( context.config ),
        max : parseInt( context.config ),
      };
    } else {
      context.config = { [ defaultEnd ] : parseInt( context.config ) };
    }
  }
  if ( _.isNumber( context.config ) ) {
    if ( defaultEnd === 'both' ) {
      context.config = { min : context.config, max : context.config };
    } else {
      context.config = { [ defaultEnd ] : context.config };
    }
  }
}
