import _ from 'lodash';
import { Validator } from '../Validator';

Validator.create<string, Record<string, any>>( {
  name : 'has',
  isValid( { value, config } ) {
    if ( ! _.isObject( value ) ) return false;
    return _.has( value, config );
  },
  message( { config } ) { return `does not have the "${config}" property`; },
} );
