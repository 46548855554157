import _ from 'lodash';
import { Type } from '../Type';

Type.create( {
  name        : 'github-id',
  validators  : {
    string      : true,
    min_length  : 1,
    max_length  : 15,
    regexp      : /^[0-9]+$/u,
  },
  coerce( value ) {
    if ( _.isNil( value ) ) return null;
    return value.toString();
  },
  form        : { type : 'Text' },
  face        : { type : 'Text' },
} );

Type.create( {
  name        : 'github-login',
  validators  : {
    string      : true,
    min_length  : 1,
    max_length  : 39,
    /* Restrictions according to the Join GitHub page
     * - may only contain alphanumeric characters or hyphens.
     * - cannot have multiple consecutive hyphens.
     * - cannot begin or end with a hyphen.
     * - Maximum is 39 characters.
     */
    regexp      : /^[a-z\d](?:[a-z\d]|-(?=[a-z\d]))*$/iu,
  },
  form        : { type : 'Text' },
  face        : { type : 'Text' },
} );
