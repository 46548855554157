import _ from 'lodash';
import { mkdebug, ValidationError } from '@ssp/utils';

import { Validator } from '../Validator';

const debug = mkdebug( 'ssp:types:validators:characters' );

type CharType = {
  name: string;
  rule?: string;
  category?: string;
  include?: string[];
  alias?: string[];
  chars?: string;
};
export const types: Record<string, Omit<CharType, 'name'>> = {
  alphabetic    : {
    rule      : 'letters from any Unicode language',
    category  : 'Alphabetic',
  },
  lowercase     : {
    rule      : 'lowercase letters from any Unicode language',
    category  : 'Lowercase',
  },
  uppercase     : {
    rule      : 'uppercase letters from any Unicode language',
    category  : 'Uppercase',
  },
  alpha         : {
    rule      : 'Latin letters',
    include   : [ 'upper', 'lower' ],
  },
  lower         : {
    rule      : 'lowercase Latin letters',
    chars     : 'abcdefghijklmnopqrstuvwxyz',
  },
  upper         : {
    rule      : 'uppercase Latin letters',
    chars     : 'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
  },
  numbers       : {
    rule      : 'numbers',
    alias     : [ 'digit', 'number' ],
    chars     : '0123456789',
  },
  alphanumeric  : {
    alias     : [ 'alnum' ],
    include   : [ 'alpha', 'numbers' ],
  },
  punctuation   : {
    rule      : 'ASCII punctuation',
    alias     : [ 'punct' ],
    chars     : [
      '!', '"', '#', '$', '%', '&', "'", '(', ')', '*', '+', ',', '-',
      '.', '/', ':', ';', '<', '=', '>', '?', '@', '[', ']', '^', '_',
      '`', '{', '|', '}', '~', '.', '\\',
    ].join( '' ),
  },
  /* TODO - `\p{Emoji}` doesn't actually work correctly...
  emoji         : {
    desc      : 'Emoji',
    category  : 'Emoji',
    // re        : /\p{Emoji}/u,
  },
  */
  hex           : {
    rule      : 'hexadecimal digits',
    alias     : [ 'xdigit' ],
    chars     : '0123456789ABCDEFabcdef',
  },
  lowerhex      : {
    rule      : 'lowercase hexadecimal digits',
    chars     : '0123456789abcdef',
  },
  upperhex      : {
    rule      : 'uppercase hexadecimal digits',
    chars     : '0123456789ABCDEF',
  },
  space         : { rule : 'spaces', chars : ' ' },
  slugpunct     : { chars : '-_.' },
  slug          : { include : [ 'upper', 'lower', 'number', 'slugpunct' ] },
  lowerslug     : { include : [ 'lower', 'slugpunct' ] },
  upperslug     : { include : [ 'upper', 'slugpunct' ] },
  underscore    : { rule : 'underscores', chars : '_' },
  dash          : { rule : 'dashes', chars : '-' },
};

const typemap: Record<string, CharType> = {};
_.each( types, ( conf_in, name ) => {
  const conf = { name, ...conf_in };
  const names = _.compact( _.flatten( _.at( conf, [
    'name', 'alias', 'category',
  ] ) ) );
  _.each( names, x => {
    if ( typemap[ x ] ) throw new Error( `Duplicate type ${x}` );
    typemap[ x ] = conf;
  } );
} );

Validator.create<unknown, string>( {
  name : 'characters',
  parse( opts ) {
    debug( 'PARSE', opts );
    const xfm = ( val ) => {
      if ( _.isArray( val ) ) return _.flatMap( val, xfm );
      if ( _.isString( val ) ) return val.split( /[\s+,]+/u );
    };
    if ( _.isArray( opts.config ) ) {
      opts.config = { body : xfm( opts.config ) };
      return;
    }
    if ( _.isPlainObject( opts.config ) ) {
      let did = 0;
      [ 'head', 'body', 'tail' ].forEach( x => {
        if ( ! opts.config[ x ] ) return;
        did++;
        opts.config[ x ] = xfm( opts.config[ x ] );
      } );
      if ( did ) return;
    }
    throw new Error( `characters: Config specifies no characters` );
  },
  validate( context ) {
    debug( 'VALIDATE', context );
    const validator = this.makeValidator( context );
    validator( context.value );
  },
  parseConfig( config ) {
    const res = {};
    const add = ( key, ...vals ) => {
      const val = _.uniq( _.filter( _.flattenDeep( vals ), _.isString ) );
      if ( val.length ) res[ key ] = val;
    };
    if ( _.isPlainObject( config ) ) {
      add( 'head', config.head );
      add( 'body', config.body );
      add( 'tail', config.tail );
    } else if ( _.isArray( config ) || _.isString( config ) ) {
      add( 'body', config );
    }
    if ( _.isEmpty( res ) ) {
      throw new Error( `characters: Invalid config` );
    }
    return res;
  },
  makeCheckers( opts ) {
    const config = _.omitBy( this.parseConfig( opts.config ), _.isNil );
    if ( _.isEmpty( config ) ) {
      throw new Error( `characters: Unable to parse config` );
    }
    return _.mapValues( config, makeTest );
  },
  makeValidator( context ) {
    const { head, body, tail } = this.makeCheckers( context );
    if ( ! ( head || body || tail ) ) {
      throw new Error( `characters: Unable to parse config` );
    }
    return ( value ) => {
      const points = _.split( value, '' );
      const bail = ( msg, index ) => {
        throw new ValidationError( msg, {
          data    : {
            schema    : context.schema,
            context,
            character : JSON.stringify( points[ index ] ),
            value     : JSON.stringify( value ),
            points    : JSON.stringify( points ),
          },
          tags    : {
            schema    : context.schema,
          },
          index,
        } );
      };
      if ( ! _.isString( value ) ) bail( 'must be a string', 0 );
      if ( points.length === 0 ) bail( 'must be a non-empty string', 0 );
      if ( head && ! head( points[ 0 ] ) ) {
        bail( 'starts with an invalid character', 0 );
      }
      if ( tail && ! tail( points[ points.length - 1 ] ) ) {
        bail( 'ends with an invalid character', points.length - 1 );
      }
      if ( body ) {
        const bad = _.findIndex( points, x => ! body( x ), head ? 1 : 0 );
        if ( bad === -1 ) return;
        // If we had a tail and we got here then the tail is ok
        if ( tail && bad === points.length - 1 ) return;
        bail( 'contains an invalid character', bad );
      }
    };
  },
  getRules( opts ) {
    const conf = this.parseConfig( opts.config );
    const rules = _.mapValues( conf, buildRules );
    const labels = {
      head  : 'must start with',
      body  : 'must contain only',
      tail  : 'must end with',
    };
    return _.reject( _.map( [ 'head', 'body', 'tail' ], which => {
      const list = _.uniq( _.compact( rules[ which ] ) );
      if ( ! list.length ) return;
      const label = labels[ which ];
      if ( list.length === 1 ) return `${label} ${list[ 0 ]}`;
      return [ `${label}:` ].concat( list );
    } ), _.isEmpty );
  },

} );

function makeTest( config ) {
  if ( config.test ) return config.test;
  const built = configProcessor( config, {
    chars( conf ) {
      const chars = Array.from( conf );
      return x => chars.includes( x );
    },
    re( conf ) { return conf.test.bind( conf ); },
    category( conf ) { return this.re( new RegExp( `\\p{${conf}}`, 'u' ) ); },
    filter    : _.isFunction,
  } );
  if ( built.length === 0 ) {
    throw new Error( `Unable to makeTest for ${config.name}` );
  }
  if ( built.length === 1 ) return built[ 0 ];
  return config.test = _.overSome( built );
}

function configProcessor( config, opts ) {
  opts.proc = ( conf ) => {
    if ( _.isNil( conf ) ) return;
    if ( _.isString( conf ) ) {
      if ( typemap[ conf ] ) {
        return opts.proc( typemap[ conf ] );
      } else if ( opts.string ) {
        return opts.string( conf );
      } else if ( opts.chars ) {
        const chars = _.uniq( _.flatMap( _.flattenDeep( conf ), x => {
          return _.isString( x ) ? Array.from( x ) : [];
        } ) );
        if ( chars.length === 0 ) return;
        return opts.chars( chars );
      }
    }
    if ( _.isArray( conf ) ) return _.map( conf, x => opts.proc( x ) );
    if ( _.isRegExp( conf ) ) return opts.regexp( conf );
    if ( _.isPlainObject( conf ) ) { return opts.config( conf ); }
    throw new Error( `Cannot process config from ${conf}` );
  };
  _.defaults( opts, {
    include( conf ) { return opts.proc( conf ); },
    config( conf ) {
      return _.map( conf, ( val, key ) => {
        if ( _.isFunction( this[ key ] ) ) return this[ key ]( val );
      } );
    },
  } );
  const res = _.flattenDeep( opts.proc( config ) );
  if ( opts.filter ) return _.filter( res, opts.filter );
  if ( opts.reject ) return _.reject( res, opts.reject );
  return _.reject( res, _.isNil );
}

function buildRules( config ) {
  return configProcessor( config, {
    config( conf ) {
      const { rule, include, category, chars, re } = conf;
      if ( rule ) return rule;
      if ( include ) return this.include( include );
      if ( category ) return `from the Unicode category "${category}"`;
      if ( chars ) {
        const chrs = Array.from( chars ).map( x => JSON.stringify( x ) );
        const last = chrs.pop();
        if ( ! chrs.length ) return last;
        return [ chrs.join( ', ' ), 'or', last ].join( ' ' );
      }
      if ( re ) return `matching the regular expression ${re}`;
      return JSON.stringify( conf );
    },
  } );
}
