import { Validator } from '../Validator';

// eslint-disable-next-line @typescript-eslint/ban-types
Validator.create<Function, any>( {

  name : 'functional',

  parse( opts ) {
    if ( typeof opts.config !== 'function' ) {
      throw new TypeError( `functional: config must be a function` );
    }
    const o = opts as $TSFixMe;
    if ( ! o.function ) o.function = this.getFunctionName( opts.config );
  },

  isValid( opts ) {
    const { value, config } = opts;
    return config.call( this, value, opts );
  },

  getFunctionName( fn ) {
    if ( fn.name ) return fn.name;
    const x = fn.toString().match( /function ([^(]+)/u );
    if ( x ) return x[ 1 ];
    return 'UNKNOWN';
  },

} );
