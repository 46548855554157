import { Type } from '../Type';

Type.create( {
  name        : 'id',
  validators  : {
    string        : true,
    min_length    : 1,
    max_length    : 278, // abnormally large to capture confluence_id reqs
  },
  form        : { type : 'Text' },
  face        : { type : 'Text' },
} );
