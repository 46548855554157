import _ from 'lodash';
import { Validator } from '../Validator';

const reBAH = /^CN=.+DC=bah\s*,\s*DC=com$/ui;

// CN=CDM Lab Managers,OU=Distribution List,DC=resource,DC=ds,DC=bah,DC=com

const reCSN = /^CN=.+DC=(csn|c4n)\s*,\s*DC=internal$/ui;
Validator.create<unknown, string>( {
  name        : 'dn',
  message     : 'Invalid Syntax',
  form        : { type : 'Text' },
  face        : { type : 'Text' },
  isValid( { value, schema } ) {
    if ( ! _.isString( value ) ) return false;
    if ( schema.startsWith( 'BAH' ) ) return reBAH.test( value );
    if ( schema.startsWith( 'CSN' ) ) return reCSN.test( value );
  },
} );
