import _ from 'lodash';
import { Validator } from '../Validator';
import { RangeConfigType, RangeOptionType, parseRange } from './utils';

function create(
  name: string, defaultEnd: 'min'|'max', aliases: string[], inclusive: boolean,
) {
  Validator.create<RangeConfigType, string, RangeOptionType>( {
    name,
    aliases,
    isValid( { value, config : { min=0, max=Infinity } } ) {
      if ( ! _.isNumber( value ) ) return false;
      if ( inclusive ) {
        return ( value >= min ) && ( value <= max );
      } else {
        return ( value > min ) && ( value < max );
      }
    },
    message( { config } ) {
      const { min, max } = config;
      if ( min && max ) {
        if ( min === max ) {
          return `must be exactly ${min}`;
        } else {
          return `must be between ${min} and ${max}`;
        }
      } else if ( min ) {
        return `must be at least ${min}`;
      } else if ( max ) {
        return `must be at most ${max}`;
      }
    },
    parse( context ) { parseRange( context, defaultEnd ); },
  } );
}

create( 'range', 'max', [], true );
create( 'min', 'min', [ 'gte' ], true );
create( 'max', 'max', [ 'lte' ], true );
create( 'gt', 'min', [], false );
create( 'lt', 'max', [], false );
