import { Type } from '../Type';

Type.create( {
  name        : 'email',
  validators  : [ 'string', 'email' ],
  form        : {
    type        : 'Email',
  },
  face        : {
    type        : 'Email',
  },
  faker       : 'internet.email',
} );
