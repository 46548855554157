import _ from 'lodash';
import { Type } from '../Type';

Type.create( {
  name        : 'number',
  validators  : [ 'number' ],
  coerce( value ) {
    if ( _.isNil( value ) ) return null;
    if ( _.isNumber( value ) && ! _.isNaN( value ) ) return value;
    if ( _.isObject( value ) ) return null;
    if ( value === '' ) return null;
    const number = Number( value );
    if ( _.isNaN( number ) ) return null;
    return number;
  },
  form        : {
    type        : 'Number',
  },
  face        : {
    type        : 'NumberBadge',
  },
  faker : 'random.number( { "min": 1, "max": 1000, "precision": 0.001 } )',
} );
