import _ from 'lodash';
import { Validator } from '../Validator';
import { ValidatorRequest, ValidatorConfig } from '../request';

// TODO - make this work when the sub-validators are async
Validator.create<ValidatorConfig[], any, ValidatorRequest>( {
  name    : 'every',
  parse( opts ) { opts.config = Validator.parse( opts.config ); },
  validate( opts ) {
    if ( ! ( _.isArray( opts.value ) || _.isPlainObject( opts.value ) ) ) {
      const msg = `The ${opts.param} validator expects an array or object`;
      throw new TypeError( msg );
    }
    _.each( opts.value, ( value, index ) => {
      Validator.run( opts.config, {
        ...opts, value,
        param : `${opts.param}.${index}`,
      } );
    } );
  },
} );
