import _ from 'lodash';
import { Type } from '../Type';

const options = {
  DoD           : 'DoD',
  Commercial    : 'Commercial',
  Federal_Civil : 'Federal Civil',
  International : 'International',
  Intelligence  : 'Intelligence',
};

Type.create( {
  name        : 'client-org',
  validators  : {
    string  : true,
    options : _.keys( options ),
  },
  form        : {
    type    : 'Select',
    options : _.map( options, ( label, value ) => ( { label, value } ) ),
  },
  face        : {
    type    : 'Text',
  },
} );
