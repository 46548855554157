import _ from 'lodash';
import { Param, ParamOptions, ParamConfig } from './Param';
import { Validator } from './Validator';
import {
  mkdebug, promiseProps,
  ValidationError, ValidationErrors,
} from '@ssp/utils';
import { ValidatorRequest, ValidatorConfig } from './request';
import { ValidationContext } from './context';

const debug = mkdebug( 'ssp:types:schema' );

export type SchemaBuildOptions =
  | SchemaOptions
  | ParamOptions[]
  | Record<string, Omit<ParamOptions, 'name'>>;

export interface SchemaOptions {
  /** The name of this parameter schema. */
  name?: string;

  /** The parameters that are part of this schema. */
  params?: ParamOptions[] | Record<string, Omit<ParamOptions, 'name'>>;

  /** Default properties for parameters added to this. */
  defaults?: ParamConfig;

  /** Schema-level Validators configuration. */
  validators?: ValidatorRequest;
  validator?: ValidatorRequest;
  validate?: ValidatorRequest;

  /** Default context options. */
  options?: Partial<Pick<ValidationContext, 'coerce'|'strict'|'defaultValues'>>;
}

export class Schema {

  /** The name of this parameter schema. */
  name: string = 'Unnamed Schema';

  /** The parameters that are part of this schema. */
  params: Record<string, Param> = {};

  /** Default properties for parameters added to this. */
  defaults: ParamConfig = {
    required    : true,
  };

  /** Schema-level Validators configuration. */
  validators: ValidatorConfig[] = [];

  /** Default context options. */
  options: SchemaOptions['options'] = {
    coerce        : false,
    strict        : true,
    defaultValues : true,
  };

  constructor( options: SchemaOptions ) {
    this.configure( options );
  }

  configure( options: Partial<SchemaOptions> = {} ) {
    const {
      name,
      validators, validator, validate, params,
      defaults,
      ...opts
    } = options;
    _.assign( this, _.omitBy( { name }, _.isNil ) );
    _.assign( this.defaults, defaults );
    _.assign( this.options, _.omitBy( _.pick( opts, [
      'coerce', 'strict', 'defaultValues',
    ] ), _.isNil ) );
    if ( params ) this.addParams( params as $TSFixMe );
    this.validators = Validator.parse( validators, validator, validate );
    return this;
  }

  addParams( params: Record<string, Omit<ParamOptions, 'name'>> ): Param[];
  addParams( params: ParamOptions[] ): Param[];
  addParams(
    params: Record<string, Omit<ParamOptions, 'name'>> | ParamOptions[],
  ) {
    if ( _.isArray( params ) ) {
      return _.map( params, conf => this.addParam( conf.name, conf ) );
    } else {
      return _.map( params, ( conf, name ) => this.addParam( name, conf ) );
    }
  }

  addParam( name: string, conf: Omit<ParamOptions, 'name'> ) {
    if ( ! name ) return;
    const param = this.makeParam( { name, ...this.defaults, ...conf } );
    this.params[ name ] = param;
    _.each(
      _.compact( _.flattenDeep( _.at( conf, 'alias', 'aliases' ) ) ),
      alias => this.params[ alias ] = param,
    );
    return param;
  }

  makeParam( options: ParamOptions ) { return new Param( options ); }

  async validate( doc: Record<string, any>, context: ValidationContext = {} ) {
    if ( ! _.isPlainObject( doc ) ) {
      throw new TypeError( `Cannot validate Schema without document` );
    }
    const {
      safe, ...opts
    } = _.defaults( {}, context, this.options, { schema : this.name } );
    debug( 'VALIDATE:', doc, context, opts );

    if ( _.isString( opts.params ) ) opts.params = [ opts.params ];
    if ( _.isEmpty( opts.params ) ) opts.params = _.keys( this.params );

    const results = {};

    if ( opts.coerce ) this.coerce( doc, opts );
    if ( opts.defaultValues ) this.defaultValues( doc, opts );
    if ( ! opts.keep ) this.cleanup( doc, opts );

    if ( opts.strict ) {
      _.each( opts.params, name => {
        if ( this.hasParam( name ) ) return;
        results[ name ] = new ValidationError( {
          message : `Unknown parameter "${name}"`,
          tags    : {
            schema : this.name,
          },
          data    : {
            schema    : this.name,
            param     : name,
            field     : name,
            validator : 'strict',
          },
        } );
      } );
      if ( ! opts.keep ) {
        _.each( _.omit( doc, opts.params ), ( val, key ) => {
          results[ key ] = new ValidationError( {
            message : `Unknown property "${key}"`,
            tags    : {
              schema    : this.name,
            },
            data    : {
              schema    : this.name,
              property  : key,
              value     : val,
              validator : 'strict',
            },
          } );
        } );
      }
    }
    if ( ! opts.keep ) doc = _.pick( doc, opts.params );

    opts.doc = doc;

    _.each( opts.params, name => {
      const param = this.getParam( name );
      if ( results[ name ] ) return;
      results[ name ] = param.validate( doc[ name ], { ...opts, safe : true } );
    } );

    results[ '*' ] = this.validateSchema( opts ).catch( err => err );
    const res = await promiseProps( results );
    const errs = _.pickBy( res, _.isError );
    if ( ! _.isEmpty( errs ) ) {
      const err = new ValidationErrors( {
        errors  : errs,
        data    : {
          context : opts,
          schema  : this.name,
        },
        tags    : {
          schema  : this.name,
        },
        thrower : this.validate,
      } );
      if ( safe ) return err;
      throw err;
    }
    return doc;
  }

  async validateSchema( context: ValidationContext ) {
    debug( 'VALIDATE SCHEMA:', context, this.validators );
    return Validator.run( this.validators, { ...context } );
  }

  defaultValues( doc: Record<string, any>, context: ValidationContext ) {
    const params = context.params || Object.keys( this.params );
    for ( const name of params ) {
      const param = this.getParam( name );
      if ( _.isUndefined( param.default ) ) continue;
      if ( ! _.isUndefined( doc[ name ] ) ) continue;
      if ( typeof param.default === 'function' ) {
        doc[ name ] = param.default.call( doc, context );
      } else {
        doc[ name ] = _.cloneDeep( param.default );
      }
    }
  }

  coerce( doc: Record<string, any>, context: ValidationContext = {} ) {
    const params = context.params || Object.keys( this.params );
    for ( const name of params ) {
      const param = this.getParam( name );
      const value = param.coerce( doc[ name ], context );
      if ( ! _.isNil( value ) ) doc[ name ] = value;
    }
  }

  cleanup( doc: Record<string, any>, context: ValidationContext = {} ) {
    if ( context.keep ) return;
    for ( const key of Object.getOwnPropertyNames( doc ) ) {
      if ( ! this.params[ key ] ) {
        // log.debug( 'DELETING PROPERTY:', key );
        // delete doc[ key ];
      }
    }
  }

  getParam( name: string ) { return this.params[ name ]; }
  hasParam( name: string ) { return !! this.params[ name ]; }

  static from( config: SchemaBuildOptions, opts: SchemaOptions = {}  ) {
    if ( _.isArray( config ) ) {
      opts.params = config;
    } else if ( config.params ) {
      opts = { ...opts, ...config };
    } else {
      opts.params = config as $TSFixMe;
    }
    return new Schema( opts );
  }

  toJSON() {
    return {
      name        : this.name,
      params      : _.mapValues( this.params, p => p.toJSON() ),
      defaults    : this.defaults,
      validators  : this.validators,
      options     : this.options,
    };
  }
}
