import { Type } from '../Type';

Type.create( {
  name        : 'fqdn',
  validators  : [ 'string', 'fqdn' ],
  form        : {
    type        : 'Fqdn',
  },
  face        : {
    type        : 'Text',
  },
  faker       : 'internet.domain',
} );
