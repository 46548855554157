import _ from 'lodash';
import { Validator } from '../Validator';
import { mkdebug } from '@ssp/utils';

const debug = mkdebug( 'ssp:types:validators:required' );

Validator.create( {
  name    : 'required',
  message : 'is required',
  parse( opts ) {
    debug( 'PARSE', opts );
    if ( ( opts.array || opts.object ) && _.isNil( opts.collection ) ) {
      opts.collection = true;
    }
    if ( _.isNil( opts.config ) ) return opts.config = true;
    if ( typeof opts.config === 'function' ) {
      debug( 'VALID CONFIG (function)' );
      return;
    }
    if ( _.isBoolean( opts.config ) ) {
      debug( 'VALID CONFIG (boolean)' );
      return;
    }
    throw new TypeError( `required: config must be a Function or Boolean` );
  },
  isValid( opts ) {
    // If we have a value we want to run the remaining validators even
    // if a value isn't required (so that we can throw errors if
    // a value was provided but it's wrong).

    const miss = this.isMissing( opts );
    const req = this.isRequired( opts );
    // If it's required then it must be present
    if ( req && miss ) return false;
    // If it's missing but isn't required then we bail out and skip
    // any following validators.
    if ( miss && ! req ) return Validator.BAIL;

    // If it's not missing and not required then it's fine
    return true;
  },
  isRequired( { config, ...opts } ) {
    if ( typeof config === 'function' ) config = config( opts );
    return config;
  },
  isMissing( { param, value } ) {
    const missing = _.isNil( value ) || ( _.isString( value ) && value === '' );
    debug( 'isMissing', param, '=', missing, `(${value})` );
    return missing;
  },
} );

