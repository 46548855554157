import { Type } from '../Type';

/* Restrictions according to the Slack API may only contain
 * alphanumeric (including non-latin) characters, hyphens, and underscores.
 * Maximum is 80 characters.
 */


Type.create( {
  name        : 'slack-channel-name',
  label       : 'Channel Name',
  validators  : {
    length      : '1-80',
    characters  : [ 'lowercase', 'numbers', 'underscore', 'dash' ],
  },
  form        : { type : 'Text' },
  face        : { type : 'Text' },
} );

Type.create( {
  name        : 'slack-channel-id',
  validators  : {
    string      : true,
    min_length  : 9,
    regexp      : /^[CGD][A-Z0-9]+$/u,
  },
  form        : { type : 'Text' },
  face        : { type : 'Text' },
} );

Type.create( {
  name        : 'slack-team-id',
  aliases     : [ 'slack-workspace-id' ],
  validators  : {
    string      : true,
    min_length  : 9,
    regexp      : /^T[A-Z0-9]+$/u,
  },
  form        : { type : 'Text' },
  face        : { type : 'Text' },
} );

Type.create( {
  name        : 'slack-grid-id',
  validators  : {
    string      : true,
    min_length  : 9,
    regexp      : /^E[A-Z0-9]+$/u,
  },
  form        : { type : 'Text' },
  face        : { type : 'Text' },
} );

Type.create( {
  name        : 'slack-user-id',
  validators  : {
    string      : true,
    min_length  : 9,
    regexp      : /^[WU][A-Z0-9]+$/u,
  },
  form        : { type : 'Text' },
  face        : { type : 'Text' },
} );
