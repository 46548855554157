import _ from 'lodash';
import { ValidationErrors } from '@ssp/utils';

import { Validator } from '../Validator';
import { ValidatorRequest, ValidatorConfig } from '../request';

// TODO - make this work when the sub-validators are async
Validator.create<ValidatorConfig[], any, ValidatorRequest[]>( {
  name    : 'or',
  parse( opts ) { opts.config = Validator.parse( ...opts.config ); },
  validate,
} );
function validate( opts ) {
  const errors = [];
  const isValid = _.some( opts.config, ( { type, config } ) => {
    try {
      Validator.validate( type, { ...opts, config, validator : type } );
      return true;
    } catch ( err ) {
      errors.push( err );
      return false;
    }
  } );

  if ( isValid ) return;
  throw new ValidationErrors( {
    errors,
    data    : { context : opts, schema : opts.schema },
    tags    : { schema : opts.schema },
    thrower : validate,
  } );
}
