import _ from 'lodash';
import { Validator } from '../Validator';
import { RangeConfigType, RangeOptionType, parseRange } from './utils';

function create( name: string, defaultEnd: 'min'|'max', aliases: string[] ) {
  Validator.create<RangeConfigType, string, RangeOptionType>( {
    name,
    aliases,
    isValid( { value, config : { min=0, max=Infinity } } ) {
      if ( ! _.has( value, 'length' ) ) return false;
      return ( value.length >= min ) && ( value.length <= max );
    },
    message( { config } ) {
      const { min, max } = config;
      if ( min && max ) {
        if ( min === max ) {
          return `must be exactly ${min} characters long`;
        } else {
          return `must be between ${min} and ${max} characters long`;
        }
      } else if ( min ) {
        return `must be at least ${min} characters long`;
      } else if ( max ) {
        return `must be at most ${max} characters long`;
      }
    },
    parse( context ) { parseRange( context, defaultEnd ); },
  } );
}

create( 'length', 'max', [] );
create( 'min_length', 'min', [ 'minlength' ] );
create( 'max_length', 'max', [ 'maxlength' ] );
