import _ from 'lodash';
import { Validator } from '../Validator';

Validator.create<string[], string>( {
  name    : 'choice',
  aliases : [ 'option', 'options', 'choices' ],
  parse( opts ) {
    const { config } = opts;
    if ( _.isArray( config ) && _.every( config, _.isString ) ) {
      throw new TypeError( `choice: expects an array of strings` );
    }
    if ( _.isEmpty( config ) ) {
      throw new TypeError( `choice: has no valid choices` );
    }
  },
  isValid( { value, config } ) {
    return _.includes( config, value );
  },
  message( { param, config } ) {
    const choices = _.map( config, v => JSON.stringify( v ) );
    const last = choices.pop();
    if ( choices.length > 0 ) {
      const text = choices.join( ', ' );
      return `must be one of ${text} or ${last}`;
    } else {
      return `must be ${last}`;
    }
  },
} );
