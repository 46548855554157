import { Validator } from '../Validator';
import { Schema, SchemaBuildOptions } from '../Schema';


Validator.create<Schema, unknown, SchemaBuildOptions>( {
  name    : 'schema',
  message : 'must match validation schema',
  parse( opts ) {
    if ( ! ( opts.config instanceof Schema ) ) {
      opts.config = Schema.from( opts.config, { name : opts.param } );
    }
    if ( ! ( opts.config instanceof Schema ) ) {
      log.debug( 'OPTS:', opts );
      throw new TypeError( `The schema validator requires a Schema` );
    }
  },
  isValid( { value, config } ) {
    return config.validate( value, {} ).then( res => res, err => err );
  },
} );
