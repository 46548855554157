import _ from 'lodash';
import { Validator } from '../Validator';

Validator.create<any, any[]>( {
  name : 'includes',
  isValid( { value, config } ) {
    if ( ! _.isArray( value ) && ! _.isObject( value ) ) return;
    return _.includes( value, config );
  },
  message( { param, config } ) { return `must contain "${config}"`; },
} );
