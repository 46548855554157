import { Type } from '../Type';

Type.create( {
  name        : 'charge-number',
  validators  : {
    minlength   : 14,
    maxlength   : 22,
    regexp      : /^[a-zA-Z0-9]+$/u,
  },
  form        : {
    type        : 'Text',
  },
  face        : {
    type        : 'Text',
  },
} );
