import { Type } from '../Type';

Type.create( {
  name        : 'url',
  validators  : [ 'string' ],
  form        : {
    type        : 'Url',
  },
  face        : {
    type        : 'Url',
  },
  faker       : 'internet.url',
} );
