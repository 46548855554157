import { Validator } from '../Validator';

// The e-mail address regular expression from http://emailregex.com/.
// eslint-disable-next-line max-len, no-useless-escape
const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})$/u;

Validator.create<boolean, string>( {
  name    : 'email',
  message : 'must be a valid email address',
  isValid( { value } ) { return re.test( value ); },
} );
