import _ from 'lodash';
import { Type } from '../Type';
import { parseTimeout } from '@ssp/utils';

Type.create( {
  name        : 'timeout',
  validators  : [ 'string' ],
  form        : {
    type        : 'String',
  },
  options     : [
    'default',
    'min',
    'minimum',
    'max',
    'maximum',
    'half',
    'floor',
    'ceil',
    'units',
    'unit',
    'modify',
  ],
  parse( value, opts_from ) {
    const opts = _.pick( opts_from, this.options );
    return parseTimeout( value, opts );
  },
} );
