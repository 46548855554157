import { Validator } from '../Validator';

// domain names are way more complicated than you think, but this is
// pretty good for the general case.
// eslint-disable-next-line max-len, no-useless-escape
const re = /(?=^.{4,253}$)(^((?!-)[a-zA-Z0-9-]{0,62}[a-zA-Z0-9]\.)+[a-zA-Z]{2,63}$)/u;

Validator.create<any, string>( {
  name    : 'fqdn',
  message : 'must be a fully-qualified domain name',
  isValid( { value } ) { return re.test( value ); },
} );
