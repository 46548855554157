import _ from 'lodash';
import { Type } from '../Type';
import { DateTime } from 'luxon';

Type.create( {
  name        : 'date',
  validators  : [ 'date' ],
  coerce( value ) {
    if ( _.isNil( value ) ) return null;
    if ( _.isDate( value ) ) return value;
    if ( _.isNumber( value ) ) return new Date( value );
    if ( _.isString( value ) ) {
      if ( value === '' ) return null;
      if ( /^\d+$/u.test( value ) ) return new Date( parseInt( value ) );
      // TODO - Provide some way to specify a format here
      const time = DateTime.fromISO( value );
      if ( time.isValid ) return time.toJSDate();
    }
    const time = Date.parse( value );
    if ( ! _.isNaN( time ) ) return new Date( time );
    return null;
  },
  faker       : 'date.recent',
  format( value: Date ) { return value; },
  parse( value: Date ) { return value; },
  form        : {
    type        : 'DateTime',
  },
  face        : {
    type        : 'DateTime',
  },
} );
