import _ from 'lodash';
import { Type } from '../Type';

Type.create( {
  name        : 'string',
  validators  : [ 'string' ],
  coerce( value ) {
    if ( _.isNil( value ) ) return null;
    if ( _.isNaN( value ) ) return null;
    if ( _.isString( value ) ) return value;
    return String( value );
  },
  form        : {
    type        : 'Text',
  },
  face        : {
    type        : 'Text',
  },
  faker       : 'lorem.sentence',
} );
