import { Validator } from '../Validator';
import { mkdebug } from '@ssp/utils';

const debug = mkdebug( 'ssp:types:validators:string' );

Validator.create<unknown, string>( {
  name    : 'string',
  message : 'must be a string',
  isValid( { value } ) {
    debug( 'isValid', { value } );
    return ( typeof value === 'string' )
      && ! ( ( value.includes( '\n' ) || value.includes( '\r' ) ) );
  },
} );
