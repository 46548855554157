import _ from 'lodash';
import { Type } from '../Type';

Type.create( {
  name        : 'integer',
  validators  : [ 'integer' ],
  coerce( value ) {
    if ( _.isNil( value ) ) return null;
    if ( _.isInteger( value ) ) return value;
    if ( _.isObject( value ) ) return null;
    if ( value === '' ) return null;
    const number = Number( value );
    if ( _.isNaN( number ) ) return null;
    if ( _.isInteger( number ) ) return number;
    return null;
  },
  form        : {
    type        : 'Number',
  },
  face        : {
    type        : 'Text',
  },
  faker       : 'random.number',
} );
