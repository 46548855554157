import _ from 'lodash';
import { Validator } from '../Validator';

Validator.create<( string | RegExp )[], string>( {
  name    : 'reserved',
  message : 'value is reserved',
  parse( opts ) {
    const chk = _.overSome( [ _.isString, _.isRegExp ] );
    const valid = _.isArray( opts.config ) && _.every( opts.config, chk );
    if ( ! valid ) {
      throw new TypeError(
        `reserved: config must be an array of strings or regular expressions`,
      );
    }
  },
  isValid( opts ) {
    if ( ! _.isString( opts.value ) ) {
      throw new Error( 'must be a string' );
    }
    const strings = _.filter( opts.config, _.isString );
    const regexes = _.filter( opts.config, _.isRegExp );
    if ( strings.length ) {
      if ( strings.includes( opts.value ) ) return false;
    }
    if ( regexes.length ) {
      for ( const regex of regexes ) {
        if ( regex.test( opts.value ) ) return false;
      }
    }
    return true;
  },
} );
