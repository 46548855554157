import _ from 'lodash';
import { Type } from './Type';
import { Validator } from './Validator';
import { mkdebug, registerSecrets } from '@ssp/utils';
import { Schema, SchemaBuildOptions } from './Schema';
import { ValidatorRequest, ValidatorConfig } from './request';
import { ValidationContext } from './context';

const debug = mkdebug( 'ssp:types:param' );

export interface ParamConfig {

  /** The {@link Type} of this parameter. */
  type?: string;

  label?: string;

  /** A human-readable (short) description of this option. */
  description?: string|string[];
  describe?: string|string[];
  desc?: string|string[];

  /** Whether this parameter is required or not. */
  required?: boolean;
  demand?: boolean;

  /**
   * Set to true if this parameter should hold an array of the types
   * indicated.
   */
  array?: boolean;

  /**
   * Set to true if this parameter should hold an object where the
   * values are of the type indicated.  Note that this does *not*
   * simply mean the value is an object, it means that the collection
   * validators will be applied to every value in the object.
   */
  object?: boolean;

  default?: any;

  /**
   * Set this to true to flag this parameter as secret.  This flagging
   * allows for tools to mask secret values when logging or sending
   * results.
   */
  secret?: boolean;

  /**
   * A longer help message, which can be displayed by (for example) an
   * HTML form.
   */
  help?: string|string[];

  /**
   * Provide a unit for this value (milliseconds, days, megabytes,
   * etc).
   */
  unit?: string;

  /** Param-level Validators config. */
  validators?: ValidatorRequest;
  validator?: ValidatorRequest;
  validate?: ValidatorRequest;

  schema?: Schema | SchemaBuildOptions;
}

export interface ParamOptions extends ParamConfig {

  /** The parameter name. */
  name: string;

  /** The {@link Type} of this parameter. */
  type: string;
}

export class Param {

  name: string;
  type: Type;
  description?: string;
  required: boolean = true;
  array: boolean = false;
  object: boolean = false;
  help?: string;
  unit?: string;
  validators: ValidatorConfig[] = [];
  schema?: Schema;
  default?: any;

  declare secret: boolean;
  // NOTE: secret has no default value, so that the warning stuff can
  // determine if it's been set explicitly to false or not.

  constructor( options: ParamOptions ) {
    const { name, type, ...opts } = options;
    if ( _.isString( name ) ) {
      this.name = name;
    } else {
      throw new TypeError( 'Param requires name' );
    }
    if ( _.isString( type ) ) {
      this.type = Type.get( type );
      if ( ! this.type ) {
        throw new TypeError( `Invalid param type '${type}'` );
      }
    } else {
      throw new TypeError( 'Param requires type' );
    }
    this.configure( opts );
  }

  configure( options: ParamConfig ) {
    if ( ! options ) return;
    const {
      validators, validator, validate, schema,
      describe, description, desc,
      ...opts
    } = options;
    _.assign( this, opts );
    if ( this.array && this.object ) {
      throw new TypeError( 'Param cannot be both array and object' );
    }
    if ( schema ) {
      if ( schema instanceof Schema ) {
        this.schema = schema;
      } else {
        this.schema = Schema.from( schema, { name : this.name } );
      }
    }
    if ( _.isNil( this.secret ) && this.mightBeSecret() ) {
      log.warn( [
        `${this.name} appears to hold a secret value, but was not`,
        `configured with a "secret" flag to indicate this.  if this`,
        `param does hold secret data, please flag it with "secret: true"`,
        `to indicate that.  If it does not hold secret data and you want`,
        `to get rid of this warning, configure it with "secret: false"`,
      ].join( ' ' ) );
      this.secret = true;
    }
    this.description = [ description || describe || desc ].flat().join( ' ' );
    this.validators = Validator.parse(
      this.validators, validators, validator, validate,
    );
  }

  getValidators() {
    return Validator.merge(
      {
        // Note that the 'required' validator must be applied first
        // even if the param is not required, because if there is no
        // value provided and the param is not required, then the
        // required validor bails out and causes us to skip the
        // remaining validators.
        type : 'required', config : this.required,
        // If this is an array or object type then we run the
        // `required` validator against the collection, not the
        // individual items
        collection : Boolean( this.array || this.object ),
      },
      this.array && { type : 'array', collection : true },
      this.object && { type : 'object', collection : true },
      this.getType()?.getValidators(),
      this.validators,
      this.schema && { type : 'schema', config : this.schema },
    );
  }

  mightBeSecret() {
    const name = this.name.toLowerCase();
    return name.includes( 'token' )
      || name.includes( 'password' )
      || name.includes( 'secret' );
  }

  getType() {
    return Type.get( this.type ) || Type.get( 'unknown' );
  }
  parse( value, context: ValidationContext = {} ) {
    const type = this.getType();
    if ( typeof type.parse === 'function' ) {
      return type.parse( value, context );
    } else {
      return value;
    }
  }

  format( value, context: ValidationContext = {} ) {
    const type = this.getType();
    if ( typeof type.format === 'function' ) {
      return type.format( value, context );
    } else {
      return value;
    }
  }

  /**
   * Use the related Type to coerce to a valid value.
   *
   * @param value - The value to coerce.
   */
  coerce( value: any, context: ValidationContext = {} ) {
    if ( this.secret && _.isString( value ) ) registerSecrets( value );
    const coerce = ( val ) => this.getType().coerce.call( this, val, context );
    if ( this.array ) {
      return _.reject( _.flatMap( _.castArray( value ), coerce ), _.isNil );
    } else if ( this.object ) {
      return _.omitBy( _.mapValues( value, coerce ), _.isNil );
    } else {
      return coerce( value );
    }
  }

  /** Alias for secret. */
  get sensitive() { return this.secret; }
  set sensitive( value ) { this.secret = value; }

  /** Alias for description. */
  get describe() { return this.description; }

  /** Alias for description. */
  get desc() { return this.description; }

  /** Alias for required. */
  get require() { return this.required; }
  set require( req ) { this.required = req; }

  /** Alias for required. */
  get demand() { return this.required; }
  set demand( req ) { this.required = req; }

  /**
   * Inverted alias for required.  Setting optional to true is the
   * same as setting required to false, and vice-versa.
   */
  get optional() { return ! this.required; }
  set optional( opt ) { this.required = ! opt; }

  validate( value, context: ValidationContext = {} ) {
    debug( 'VALIDATE PARAM:', context, this );
    const { safe, ...opts } = context;

    return Validator.run( this.getValidators(), {
      param   : this.name,
      array   : this.array,
      object  : this.object,
      ...opts,
      value,
    } ).catch( err => {
      if ( safe ) return err;
      throw err;
    } );
  }

  rules( context: Partial<ValidationContext> = {} ) {
    return Validator.rules( this.getValidators(), {
      ..._.pick( this, 'array', 'object' ),
      ...context,
    } );
  }

  getRules( context: Partial<ValidationContext> = {} ) {
    return Validator.getRules( this.getValidators(), {
      ..._.pick( this, 'array', 'object' ),
      ...context,
    } );
  }

  toJSON() {
    return {
      name        : this.name,
      type        : this.type.name,
      description : this.description,
      required    : this.required,
      array       : this.array,
      object      : this.object,
      help        : this.help,
      unit        : this.unit,
      validators  : this.validators,
      default     : this.default,
      secret      : this.secret,
    };
  }

}
