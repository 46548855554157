import { Validator } from '../Validator';

// eslint-disable-next-line @typescript-eslint/ban-types
Validator.create<never, Function, never>( {
  name    : 'function',
  message : 'must be a function',
  parse( opts ) {
    if ( opts.config ) {
      throw new Error( [
        `The "function" validator does not take a config value,`,
        `perhaps you wanted "functional"?`,
      ].join( ' ' ) );
    }
  },
  isValid( { value } ) { return typeof value === 'function'; },
} );
