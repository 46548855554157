import _ from 'lodash';
import { Validator } from './Validator';
import { ValidatorRequest, ValidatorConfig } from './request';
import { ValidationContext } from './context';

const registry: Map<string, Type> = new Map();

export interface TypeOptions {
  name: string;
  description?: string | string[];
  help?: string | string[];
  validate?: ValidatorRequest | ValidatorRequest[];
  validator?: ValidatorRequest | ValidatorRequest[];
  validators?: ValidatorRequest | ValidatorRequest[];
  inherit?: string;
  alias?: string | string[];
  aliases?: string | string[];
  coerce?: ( value: any, options: ValidationContext ) => any;
  parse?: ( value: any, options: ValidationContext ) => any;
  format?: ( value: any, options: ValidationContext ) => any;
  /**
   * A comparison method, to determine if two values for this type are
   * equal.
   */
  equals?: <T=unknown>( a: T, B: T ) => boolean;
  [key: string]: any;
}

export class Type {

  /**
   * The name of this type.
   */
  name: string;

  /**
   * A human-readable (short) description of this type.
   */
  description?: string;

  /**
   * More detailed help information.
   */
  help?: string;

  /** Type-level Validators config. */
  validators: ValidatorConfig[] = [];
  aliases: string[] = [];

  constructor( options: TypeOptions ) {
    const {
      validators, validator, validate, inherit, alias, aliases, ...opts
    } = options;
    if ( inherit ) {
      const parent = Type.get( inherit );
      if ( ! parent ) {
        throw new TypeError( `Could not find type ${inherit} to inherit` );
      }
      _.assign( this, parent );
    }
    _.assign( this, opts );
    this.aliases = _.uniq( _.compact( _.flattenDeep( [ alias, aliases ] ) ) );
    if ( ! _.isString( this.name ) ) {
      throw new TypeError( 'Type must have a name' );
    }
    if ( ! ( typeof this.coerce === 'function' ) ) {
      throw new TypeError( 'Type.coerce must be a function' );
    }
    this.validators = Validator.parse(
      ...[ validators, validator, validate ].flat( 1 ),
    );
  }

  getValidators() { return this.validators; }

  coerce( value: any, _options: ValidationContext ) { return value; }

  parse?( value: any, options: ValidationContext ): any;
  format?( value: any, options: ValidationContext ): any;

  static create( { register=true, ...def }: TypeOptions & {
    register?: boolean
  } ) {
    const type = new this( def );
    if ( register ) {
      [ type.name, ...type.aliases ].forEach( name => {
        if ( registry.has( name ) ) {
          throw new TypeError( `Duplicate Type "${name}"` );
        }
        registry.set( name, type );
      } );
    }
    return type;
  }

  static get( type: Type ): Type;
  static get( name: string ): Type|undefined;
  static get( name: string|Type ) {
    if ( name instanceof Type ) return name;
    return registry.get( name );
  }
  static registry() { return registry; }
  static has( name ) { return registry.has( name ); }
  static names() { return Array.from( registry.keys() ); }
  static all() { return Array.from( registry.values() ); }

  static coerce( type: string, value: any, opts: ValidationContext ) {
    return this.get( type ).coerce?.call( opts, value );
  }

  toString() { return this.name; }

  rules( options: ValidationContext ) {
    return Validator.rules( this.getValidators(), options );
  }

  equals<T=unknown>( a: T, b: T ) { return _.isEqual( a, b ); }

}
