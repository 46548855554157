import _ from 'lodash';
import { ValidationContext } from './context';
import type { SelfOrArray } from '@ssp/ts';

export type ValidatorName = string; // TODO

export interface ValidatorConfig {
  type: ValidatorName;
  collection?: boolean;
  config?: any;
}
export interface ValidatorConfigs {
  [key: string]: string | number | boolean | RegExp | string[];
}
export type ValidatorFunction = ( context: ValidationContext ) => void;
export type ValidatorRequest = SelfOrArray<
  ValidatorName | ValidatorConfig | ValidatorConfigs | ValidatorFunction
>;

export function isValidatorName( value: any ): value is ValidatorName {
  return typeof value === 'string';
}
export function isValidatorFunction( value: any ): value is ValidatorFunction {
  return typeof value === 'function';
}
export function isValidatorConfig( value: any ): value is ValidatorConfig {
  return _.isPlainObject( value )
    && isValidatorName( value.type );
}
export function isValidatorRequest( value: any ): value is ValidatorRequest {
  return isValidatorName( value )
    || isValidatorFunction( value )
    || isValidatorConfig( value );
}
