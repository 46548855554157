import _ from 'lodash';
import {
  ValidationError, ValidationErrors,
  mkdebug, promiseProps, invariant,
} from '@ssp/utils';
import {
  ValidatorRequest, ValidatorConfig,
  isValidatorFunction, isValidatorConfig,
  ValidatorName,
} from './request';
import { ValidationContext } from './context';

import type { Promisable } from '@ssp/ts';

const debug = mkdebug( 'ssp:types:validator' );

const registry: Map<string, Validator> = new Map();

type Parser<C=unknown, V=unknown, O=C>
  = ( context: ValidationContext<C|O, V> ) => void;
type Checker<C=unknown, V=unknown>
  = ( context: ValidationContext<C, V> )
    => Promisable<boolean | Validator['BAIL']>;

export interface ValidatorOptions<C=unknown, V=unknown, O=C> {
  /** Name of the validator. */
  name: string;
  /**
   * Validation method that should check the data in the context and
   * return a boolean indicating if it is valid or not.
   */
  isValid?: Checker<C, V>;
  /**
   * A message or a function to generate a message for the
   * ValidationError that will be thrown for invalid values.
   */
  message?: string | ( ( context: ValidationContext<C, V> ) => string );
  /**
   * Called to normalize and validate the Validator's configuration
   * options, not to validate a document.
   */
  parse?: Parser<C, V, O>;
  /**
   * Validation method that should check the data in the context and
   * throw a ValidationError if it is invalid. This is primarily an
   * escape hatch for very specific use cases.  In the general case
   * you should use `isValid` instead.
   */
  validate?: ( context: ValidationContext<C, V> ) => void;
  [key: string]: any;
}

// C = Config Type
// V = Value Type
// O = Options Type (for when the type accepted by #parse is wider than C)
export interface Validator<C=unknown, V=unknown, O=C>
  extends ValidatorOptions<C, V, O> {}
export class Validator<C=unknown, V=unknown, O=C> {

  name: string;
  isValid?: Checker<C, V>;
  message: string | ( ( context: ValidationContext<C, V> ) => string )
    = 'Validation failed';
  parse?: Parser<C, V, O>;

  alias?: string|string[];
  aliases?: string|string[];

  static BAIL = Symbol( 'Validator#BAIL' );

  constructor( opts: ValidatorOptions<C, V, O> ) {
    _.assign( this, opts );

    if ( ! _.isString( this.name ) ) {
      throw new TypeError( 'Validator must have a name' );
    }
  }

  /**
   * @param context - Validation context options.
   */
  validate( context: ValidationContext<C, V> ) {
    if ( typeof this.isValid !== 'function' ) {
      throw new TypeError( 'isValid is not a function' );
    }
    debug( 'validate', this.name, context );
    const data = _.omitBy( context, ( _val, key ) => {
      return key.startsWith( '_' ) || ( key in [ 'doc', 'config' ] );
    } );
    data.validator = this.name;
    const error_options = {
      data,
      tags    : { schema : context.schema },
      thrower : this.validate,
    };
    try {
      const res = this.isValid( context );
      if ( res ) return res;
    } catch ( cause ) {
      throw new ValidationError( cause, error_options );
    }
    throw new ValidationError( this.getMessage( context ), error_options );
  }

  getMessage( context: ValidationContext<C, V> ) {
    let msg = this.message;
    if ( typeof msg === 'function' ) msg = msg( context );
    if ( _.isString( msg ) ) {
      if ( _.isString( context.param ) ) {
        return `${context.param} ${msg}`;
      } else {
        return msg;
      }
    }
  }

  static create<C=unknown, V=unknown, O=C>( def: ValidatorOptions<C, V, O> ) {
    const validator = new Validator( def );
    const names = _.flatten( _.at( validator, 'name', 'alias', 'aliases' ) );
    _.each( _.uniq( _.compact( names ) ), name => {
      invariant( _.isString( name ) );
      if ( registry.has( name ) ) {
        throw new TypeError( `Duplicate Validator "${name}"` );
      }
      registry.set( name, validator );
    } );
    return validator;
  }

  static registry() { return registry; }
  static get( type: ValidatorName ) {
    const val = registry.get( type );
    if ( ! val ) {
      throw new TypeError( `No validator for type "${type}"` );
    }
    return val;
  }

  static has( type: ValidatorName ) { return registry.has( type ); }

  static validate<C=unknown, V=unknown>(
    validator: ValidatorName, context: ValidationContext<C, V>,
  ) {
    if ( _.isString( validator ) && _.isPlainObject( context ) ) {
      return this.get( validator ).validate( { ...context, validator } );
    } else {
      log.warn( 'VALIDATOR:', validator, 'CONTEXT:', context );
      throw new TypeError( `Invalid validator ${validator}` );
    }
  }

  static async run<C=unknown, V=unknown>(
    validator_configs: ValidatorConfig[],
    context: ValidationContext<C, V>,
  ) {
    const validators = this.prepare( validator_configs, context );
    if ( ! validators ) return;

    debug( `VALIDATOR RUN`, validators );

    // Note that everything checking for errors below here is only
    // going to see validation errors, because the `subrun` method
    // throws other errors, but returns validation errors.
    const { param, field } = context;
    if ( context.array ) {
      const star = await this.subrun(
        _.filter( validators, 'collection' ), context,
      );
      if ( _.isError( star ) ) throw star;

      const res = await Promise.all(
        _.map( context.value as any, ( value, index ) => {
          return this.subrun( _.reject( validators, 'collection' ), {
            ...context, value, index,
            param : `${param}[${index}]`,
            ...( field ? { field : `${field}[${index}]` } : {} ),
          } );
        } ),
      );
      const errs = _.filter( res, _.isError );
      if ( ! _.isEmpty( errs ) ) {
        throw new ValidationErrors( {
          errors  : errs,
          data    : { context },
          tags    : { schema : context.schema },
          thrower : this.run,
        } );
      }
    } else if ( context.object ) {
      const star = await this.subrun(
        _.filter( validators, 'collection' ), context,
      );
      if ( _.isError( star ) ) throw star;

      const res = await promiseProps(
        _.mapValues( context.value as any, ( value, index ) => {
          return this.subrun( _.reject( validators, 'collection' ), {
            ...context, value, index,
            param : `${param}[${index}]`,
            ...( field ? { field : `${field}[${index}]` } : {} ),
          } );
        } ),
      );
      const errs = _.pickBy( res, _.isError );
      if ( ! _.isEmpty( errs ) ) {
        throw new ValidationErrors( {
          errors  : errs,
          data    : { context },
          tags    : { schema : context.schema },
          thrower : this.run,
        } );
      }
    } else {
      // If it was not a collection type, then we run all the
      // validators against the value itself
      const res = await this.subrun( validators, context );
      if ( _.isError( res ) ) throw res;
    }
  }

  static async subrun( validators, context ) {
    for ( const vconf of validators ) {
      const validator = Validator.get( vconf.validator );
      try {
        const res = await validator.validate( { ...vconf, ...context } );
        if ( res === Validator.BAIL ) return;
      } catch ( err ) {
        if ( err instanceof  ValidationError ) return err;
        if ( err instanceof ValidationErrors ) return err;
        throw err;
      }
    }
  }

  static parse( ...vals: ValidatorRequest[] ): ValidatorConfig[] {
    return this.merge( vals.flatMap( val => {
      if ( ! val ) return [];
      if ( isValidatorConfig( val ) ) return val;
      if ( isValidatorFunction( val ) ) {
        return { type : 'functional', config : val };
      }
      if ( _.isArray( val ) ) return val.flatMap( v => this.parse( v ) );
      if ( _.isPlainObject( val ) ) {
        return Object.entries( val ).flatMap( ( [ type, config ] ) => {
          return this.parse( { type, config } );
        } );
      }
      if ( _.isString( val ) ) {
        const x = /^(\w+)\((.*)\)$/u.exec( val );
        if ( x ) return this.parse( { type : x[ 1 ], config : x[ 2 ] } );
        return this.parse( { type : val } );
      }
      log.debug( 'INVALID VALS:', vals );
      throw new TypeError( `Invalid validators config: ${val}` );
    } ) );
  }

  static merge( ...vals ) {
    return _.uniqWith( _.compact( _.flattenDeep( vals ) ), _.isEqual );
  }

  static prepare(
    validators: ValidatorConfig[],
    options: ValidationContext,
  ) {
    validators = _.compact( _.flattenDeep( validators ) );
    validators = _.compact( _.map( validators, conf => {
      if ( _.includes( options.skip, conf.type ) ) return;
      const validator = Validator.get( conf.type );
      const context = { ...conf, ...options, validator : validator.name };
      if ( typeof validator.parse === 'function' ) validator.parse( context );
      return context;
    } ) );
    return validators.length && validators;
  }

  static rules(
    validators: ValidatorConfig[],
    context: Partial<ValidationContext>,
  ) {
    const rules = this.getRules( validators, context );
    return this.formatRules( rules );
  }
  static cleanRules( rules ) {
    if ( _.isString( rules ) ) return rules;
    return _.uniq( _.map( rules, r => this.cleanRules( r ) ) );
  }
  static formatRules( rules ) {
    const indent = ( str, level, idx ) => {
      const sp = ' '.repeat( ( level + Boolean( idx ) ) * 2 );
      const star = ( level || idx ) ? '* ' : '';
      return sp + star + str;
    };
    const fmt = ( data, level=0 ) => {
      if ( _.isArray( data ) ) {
        if ( data.length === 0 ) return;
        // If it's an array of nothing but other arrays then we don't
        // have a "title line" for it and we can just format all the
        // arrays at the same level
        if ( _.every( data, _.isArray ) ) {
          return _.map( data, d => fmt( d, level ) ).join( '\n' );
        }
        return _.map( data, ( val, idx ) => {
          if ( _.isArray( val ) ) return fmt( val, level + ( idx ? 1 : 0 ) );
          if ( _.isString( val ) ) return indent( val, level, idx );
          throw new Error( `Cannot format ${val}` );
        } ).join( '\n' );
      } else {
        throw new Error( `Cannot format ${data}` );
      }
    };
    return fmt( rules );
  }

  static getRules(
    validators: ValidatorConfig[],
    context: Partial<ValidationContext>,
  ): string[] {
    if ( ! _.every( validators, 'validator' ) ) {
      validators = this.prepare( validators, context as $TSFixMe );
      if ( ! validators ) return;
    }
    const run = ( vals ) => this.cleanRules( _.flatMap( vals, ( opts ) => {
      const validator = Validator.get( opts.validator );
      return validator.getRules( opts );
    } ) );
    if ( _.some( validators, 'collection' ) ) {
      const vals = _.partition( validators, 'collection' );
      const [ col, val ] = _.map( vals, run );
      if ( col.length ) col.unshift( 'The entire collection:' );
      if ( val.length ) val.unshift( 'Each value in the collection:' );
      if ( col.length && val.length ) return [ col, val ];
      if ( col.length ) return col;
      if ( val.length ) return val;
    } else {
      return run( validators );
    }
  }

  /**
   * Collect rules and format them for display.  This is expected to
   * return an array, where each element can either be a string (which
   * indicates it is a rule) or another array (in which case it's
   * treated as a rule subgroup, for example:
   *
   * @param {object} [opts] - Options object.
   * @example
   * return [
   *   'must start with "@"',
   *   [
   *     'must contain:',
   *     'lowercase Latin letters',
   *   ],
   *   'must end with "!"',
   * ];
   */
  getRules( opts ) {
    if ( typeof this.parse === 'function' ) this.parse( opts );
    if ( typeof this.message === 'function' ) {
      const msg = this.message( opts );
      if ( msg ) return msg;
    }
    if ( _.isString( this.message ) ) return this.message;
    return `must satisfy the "${this.name}" validator`;
  }

  toString() { return `Validator<${this.name}>`; }
}
