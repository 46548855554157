import { Type } from '../Type';

Type.create( {
  name        : 'markdown',
  validators  : [ 'text' ],
  coerce( value ) { return String( value ); },
  form        : {
    type        : 'Markdown',
  },
  face        : {
    type        : 'Markdown',
  },
} );
