import _ from 'lodash';
import { Validator } from '../Validator';
import { RangeConfigType, RangeOptionType, parseRange } from './utils';

type ValueType = Record<string, any> | any[];
function create( name: string, defaultEnd: 'min'|'max' ) {
  Validator.create<RangeConfigType, ValueType, RangeOptionType>( {
    name,
    isValid( { value, config : { min=0, max=Infinity } } ) {
      const size = _.size( value );
      return ( size >= min ) && ( size <= max );
    },
    message( { config : { min, max } } ) {
      if ( min && max ) {
        if ( min === max ) {
          return `must contain exactly ${min} items`;
        } else {
          return `must contain between ${min} and ${max} items`;
        }
      } else if ( min ) {
        return `must contain at least ${min} items`;
      } else if ( max ) {
        return `must contain no more than ${max} items`;
      }
    },
    parse( context ) {
      parseRange( context, defaultEnd );
      context.collection = true;
    },
  } );
}

create( 'items', 'max' );
create( 'min_items', 'min' );
create( 'max_items', 'max' );
