import { Type } from '../Type';

Type.create( {
  name        : 'employee-id',
  validators  : {
    string      : true,
    min_length  : 6,
    max_length  : 6,
    regexp      : /^[0-9]+$/u,
  },
  form        : { type : 'Text' },
  face        : { type : 'Text' },
  faker       : 'user.employee_id',
  label       : 'Employee ID',
} );
